<template>
    <canvas :id="'canvasImage' + index" @mousedown="startSelect" @mousemove="drawRect" @mouseup="stopSelect" />
</template>

<script>
import Enum from "./documents.enum";

export default {
    props: ["image", "mlResult", "ocrResult", "index", "focusedLabel"],
    watch: {
        focusedLabel: {
            handler() {
                this.ctx.drawImage(this.imageLocal, 0, 0);
                Object.keys(this.mlResult.labels).forEach(label => {
                    if (label == this.focusedLabel && this.mlResult.labels[label] && this.mlResult.labels[label].position) {
                        const positions = this.mlResult.labels[label].position.coordinates;
                        if (this.mlResult.labels[label].position.page == this.index) {
                            this.ctx.beginPath();
                            this.ctx.lineWidth = "4";
                            this.ctx.strokeStyle = Enum.labelColors[label];
                            this.ctx.rect(
                                positions[0] * this.ctx.canvas.width - 5,
                                positions[1] * this.ctx.canvas.height - 5,
                                positions[2] * this.ctx.canvas.width - positions[0] * this.ctx.canvas.width + 10,
                                positions[3] * this.ctx.canvas.height - positions[1] * this.ctx.canvas.height + 10,
                            );
                            this.ctx.stroke();
                            this.ctx.closePath();
                            this.scrollInToView(positions[1] * this.ctx.canvas.height);
                        }
                    }
                });
            },
        },
    },
    data() {
        return {
            selectionMode: false,
            startPosition: {
                x: null,
                y: null,
            },
            drawColor: "green",
            imageLocal: null,
        };
    },
    async mounted() {
        var canvas = document.getElementById("canvasImage" + this.index.toString());
        canvas.style.width = "100%";
        canvas.style.cursor = "crosshair";
        var ctx = canvas.getContext("2d");

        await new Promise(resolve => {
            var background = new Image();
            background.src = "data:image/png;base64," + this.image.image;

            background.onload = function() {
                ctx.canvas.width = background.width;
                ctx.canvas.height = background.height;
                ctx.drawImage(background, 0, 0);
                resolve();
            };
            this.imageLocal = background;
        });
        this.ctx = ctx;
        this.drawRectangles();
        // this.ocrResult.forEach(line => {
        //     if (line.page == this.index + 1) {
        //         const boundingBox = line.boundingBox;
        //         var lineStartX = boundingBox[0];
        //         var lineWidth = boundingBox[2] - lineStartX;
        //         var lineStartY = boundingBox[1];
        //         var lineHeight = boundingBox[7] - lineStartY;
        //         if (this.image.unit == "inch") {
        //             var widthFactor = this.ctx.canvas.width / this.image.width;
        //             var heightFactor = this.ctx.canvas.height / this.image.height;
        //             lineStartX *= widthFactor;
        //             lineWidth *= widthFactor;
        //             lineStartY *= heightFactor;
        //             lineHeight *= heightFactor;
        //         }
        //         this.ctx.beginPath();
        //         this.ctx.lineWidth = "2";
        //         this.ctx.strokeStyle = "grey";
        //         this.ctx.rect(lineStartX, lineStartY, lineWidth, lineHeight);
        //         this.ctx.stroke();
        //         this.ctx.closePath();
        //     }
        // });
    },
    methods: {
        scrollInToView(yPos) {
            var rootElement = document.getElementById("canvas-container");
            rootElement.scrollTo({ top: yPos + this.index * this.ctx.canvas.height - rootElement.clientHeight / 2, behavior: "smooth" });
        },
        drawRectangles() {
            Object.keys(this.mlResult.labels).forEach(label => {
                if (this.mlResult.labels[label] && this.mlResult.labels[label].position) {
                    const positions = this.mlResult.labels[label].position.coordinates;
                    if (this.mlResult.labels[label].position.page == this.index) {
                        this.ctx.beginPath();
                        this.ctx.lineWidth = "2";
                        this.ctx.strokeStyle = Enum.labelColors[label];
                        this.ctx.rect(
                            positions[0] * this.ctx.canvas.width,
                            positions[1] * this.ctx.canvas.height,
                            positions[2] * this.ctx.canvas.width - positions[0] * this.ctx.canvas.width,
                            positions[3] * this.ctx.canvas.height - positions[1] * this.ctx.canvas.height,
                        );
                        this.ctx.stroke();
                        this.ctx.closePath();
                    }
                }
            });
        },
        getPortionOfCanvas(width, height, x, y) {
            var result = "";
            this.ocrResult.forEach(line => {
                if (line.page == this.index + 1) {
                    const boundingBox = line.boundingBox;
                    var lineStartX = boundingBox[0];
                    var lineWidth = boundingBox[2] - lineStartX;
                    var lineStartY = boundingBox[1];
                    var lineHeight = boundingBox[7] - lineStartY;
                    if (this.image.unit == "inch") {
                        var widthFactor = this.ctx.canvas.width / this.image.width;
                        var heightFactor = this.ctx.canvas.height / this.image.height;
                        lineStartX *= widthFactor;
                        lineWidth *= widthFactor;
                        lineStartY *= heightFactor;
                        lineHeight *= heightFactor;
                    }
                    if (x < lineStartX && y < lineStartY) {
                        if (lineStartX + lineWidth < x + width && lineStartY + lineHeight < y + height) {
                            result += line.text + " ";
                        }
                    }
                }
            });
            this.$emit("updateLabel", result.trimEnd());
            this.drawRectangles();
        },
        startSelect(e) {
            this.selectionMode = true;
            var bx = e.target.getBoundingClientRect();
            var scaleX = this.ctx.canvas.width / bx.width;
            var scaely = this.ctx.canvas.height / bx.height;
            this.startPosition.x = (e.clientX - bx.left) * scaleX;
            this.startPosition.y = (e.clientY - bx.top) * scaely;
        },
        drawRect(e) {
            if (this.selectionMode) {
                this.ctx.drawImage(this.imageLocal, 0, 0);
                this.drawRectangles();
                this.ctx.beginPath();
                this.ctx.strokeStyle = this.drawColor;
                var bx = e.target.getBoundingClientRect();
                var scaleX = this.ctx.canvas.width / bx.width;
                var scaleY = this.ctx.canvas.height / bx.height;
                this.ctx.strokeRect(this.startPosition.x, this.startPosition.y, (e.clientX - bx.left) * scaleX - this.startPosition.x, (e.clientY - bx.top) * scaleY - this.startPosition.y);
                this.ctx.closePath();
            }
        },
        stopSelect(e) {
            var bx = e.target.getBoundingClientRect();
            var scaleX = this.ctx.canvas.width / bx.width;
            var scaleY = this.ctx.canvas.height / bx.height;
            this.getPortionOfCanvas((e.clientX - bx.left) * scaleX - this.startPosition.x, (e.clientY - bx.top) * scaleY - this.startPosition.y, this.startPosition.x, this.startPosition.y);
            this.selectionMode = false;
            this.startPosition.x = null;
            this.startPosition.y = null;
        },
    },
};
</script>
